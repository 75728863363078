import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';
import { MetatagType } from '../types';
// import { Link } from 'gatsby';


const About = ({ data, location }) => {
  return (
    <Layout className="about-us" location={location}>
      <SEO metatags={data.about?.metatags} />
      <section className="section">
        <div className="container is-fluid">
          <section className="section-us section-us__text-img invert">
            <div className="section-us__inner">
            <div className="text-img-text">
            <h2>We are</h2>
            <h4>MORE THAN GREAT DEVELOPERS</h4>
            <p>At <em>Easytechgreen</em>, experience is our product.Our mission is to be one of the leading providers of open source solutions in the world.We build, and maintain fresh <strong>Drupal</strong> and React websites using an agile and collaborative approach to client relationships in order to create custom digital solutions.</p>
            <a className="btn btn-big" href="/contact">Contact Us</a>
            </div>
            <div className="text-image-img">
              {/*<img src="https://drupal-prod.easytechgreen.com/sites/default/files/styles/large/public/media/2021-06/bg1.jpeg?itok=oLnJLwEy" alt="" />*/}
            </div>
            </div>
            </section>
            </div>
          </section>
        </Layout>
        );
};

        About.propTypes = {
          data: PropTypes.shape({
          about: PropTypes.shape({
          title: PropTypes.string,
        metatags: PropTypes.arrayOf(MetatagType),
        relationships: PropTypes.shape({
          sections: PropTypes.array
      })
    })
  }),
        location: PropTypes.object
};

        export default About;

        export const fragment = graphql`
        query AboutQuery {
          about: nodePage(path: {alias: {eq: "/about"}}) {
          title
      metatags: metatag_normalized {
          ...PageMetatag
        }
        relationships {
          sections: field_page_sections {
          ...ParagraphCoverLite
          ...ParagraphImageText
        ...ParagraphTeamMembers
        }
      }
    }
  }
        `;
